<template>
  <div id="app">
     <!--  <router-view/> -->
     <!--  <regresos></regresos> -->
      <!--  <webinar></webinar> -->
   <!-- </div> -->

   
    <div style="">
      <regresos></regresos>
    </div>

   
  </div>

</template>
<script>
import regresos from './components/regresos-component.vue'

export default {
  components: {
    regresos,
  }
}
</script>
