import VueLoadingButton from 'vue-loading-button'
  import axios from "axios";
  
  export default {
      components: {
    VueLoadingButton,
  },
    /// inicializacion eventos
  mounted() {
    console.log('Component mounted.')
  },
  ///Datos vue
  data(){
    return {

      NOMBRE: null,
      APELLIDO_PATERNO: null,
      APELLIDO_MATERNO: null,
      TELEFONO_CELULAR: null,
      TELEFONO_DOMICILIO: null,
      TELEFONO_TRABAJO: null,
      CORREO_ELECTRONICO: null,
      CAMPANIA_INICIAL_CARGA_BASE: "REGRESOS UVM",
      CAMPANIA: null,
      SUBCAMPANIA: null,
      ESTADO_OPORTUNIDAD: "ACTIVO",
      PRIORIDAD_MARCACION: null,
      SCORE: null,
      DESBORDE_CCUVM_CAMPUS: null,
      DESBORDE_CAMPUS_CCUVM: null,
      CICLO: null,
      CAMPUS: null,
      REGION: null,
      NIVEL: null,
      PROGRAMA: null,
      FUENTE_OBTENCION: null,
      FUENTE_NEGOCIO: "WEB REGRESOS",
      TRACKING_CODE: null,
      TIPO_BASE: null,
      REVALIDACION_EQUIVALENCIAS: null,
      CLAVE_ESCUELA_PROCEDENCIA: null,
      NOMBRE_EMPRESA: null,
      PRESENTARA_EXAMEN_ESCUELA_PUBLICA: null,
      ESCUELA_SECTOR: null,
      ESCUELA_ALIANZA:null,
      ETAPA_VENTA: null,
      CLASIFICACION_TIPIFICACION: null,
      TIPIFICACION: null,
      TIPIFICACION_BI: null,
      CONTADOR_TOQUES: null,
      OPORTUNIDAD_DUPLICADA: null,
      FECHA_INSERCION_CRM: null,
      FECHA_PRIMER_TOQUE: null,
      FECHA_CONTACTADO: null,
      FECHA_ULTIMA_CITA: null,
      FECHA_ULTIMA_ASISTENCIA: null,
      FECHA_ULTIMA_MODIFICACION: null,
      NO_PERSONA: null,
      CALIDAD_REGISTRO: null,
      FECHA_INSERCION_MARCADOR: null,
      FECHA_ULTIMA_MODIFICACION_MARCADOR: null,
      CICLO_OBTENCION: null,
      CICLO_ULTIMA_ASISTENCIA: null,
      CICLO_ULTIMA_CITA: null,
      CANAL_ACTUALIZADO: null,
      items:[],
      seen: false,
      seen2: false,
      seen3: false,
      seenbuton:true,
      seelayout2:true,
      seelayout3:true,
      seelayout4:false,
      info:[],
      telvalidar: '',
      correovalidar: '' ,
      errors: [],
      errors2: [],
      sucsess: [],
      isLoading: false,
      isStyled: false,
      isLoading1: false,
      isStyled1: false,
    };
  },
  ///Metodos vue
  methods: {
    reset() {
      Object.assign(this.$data, this.$options.data.call(this));
    },
    consultar () {
    if (!this.numeroalumno){
                this.errors.push('El número de cuenta no debe estar vacío.');
    }else{
        if (this.numeroalumno.length <6 || this.numeroalumno.length >9) {
                    this.errors.push('La cuenta debe de contener al menos 6 dígitos y un máximo de 9.');
                }else{
         // let contract = params.get('prodId');
          this.isLoading1 = true;
      axios.get('https://consulta-regresos-dot-uvm-contact-center.uc.r.appspot.com/regresos/'+this.numeroalumno).then((result) => {
        console.log(result.data);
        let DATE=new Date();
        this.DATE1=DATE.getFullYear()+'-'+DATE.getMonth()+'-'+DATE.getDay()+'T'+DATE.getHours()+':'+DATE.getMinutes()+':'+DATE.getSeconds();
        this.info2 = result.data
        if (this.info2.code=== "200"){
          this.info = result.data.data
          this.seelayout2=false
          this.isLoading1 = false;
          this.seen=true
          this.NOMBRE=this.info[0].NOMBRE;
          this.APELLIDO_PATERNO=this.info[0].APELLIDO_PATERNO;
          this.APELLIDO_MATERNO=this.info[0].APELLIDO_MATERNO;
          this.TELEFONO_DOMICILIO=this.info[0].TELEFONO_DOMICILIO;
          this.TELEFONO_TRABAJO=this.info[0].TELEFONO_TRABAJO;
          this.CAMPANIA_INICIAL_CARGA_BASE=this.info[0].CAMPANIA_INICIAL_CARGA_BASE;
          this.CAMPANIA=this.info[0].CAMPANIA;
          this.SUBCAMPANIA=this.info[0].SUBCAMPANIA;
          //this.ESTADO_OPORTUNIDAD=this.info[0].ESTADO_OPORTUNIDAD;
          this.PRIORIDAD_MARCACION=this.info[0].PRIORIDAD_MARCACION;
          this.SCORE=this.info[0].SCORE;
          this.DESBORDE_CCUVM_CAMPUS=this.info[0].DESBORDE_CCUVM_CAMPUS;
          this.DESBORDE_CAMPUS_CCUVM=this.info[0].DESBORDE_CAMPUS_CCUVM;
          this.CICLO=this.info[0].CICLO;
          this.CAMPUS=this.info[0].CAMPUS;
          this.REGION=this.info[0].REGION;
          this.NIVEL=this.info[0].NIVEL;
          this.PROGRAMA=this.info[0].PROGRAMA;
          //this.FUENTE_OBTENCION=this.info[0].FUENTE_OBTENCION;
          this.FUENTE_NEGOCIO=this.info[0].FUENTE_NEGOCIO;
          this.TRACKING_CODE=this.info[0].TRACKING_CODE;
          this.TIPO_BASE=this.info[0].TIPO_BASE;
          this.REVALIDACION_EQUIVALENCIAS=this.info[0].REVALIDACION_EQUIVALENCIAS;
          this.CLAVE_ESCUELA_PROCEDENCIA=this.info[0].CLAVE_ESCUELA_PROCEDENCIA;
          this.NOMBRE_EMPRESA=this.info[0].NOMBRE_EMPRESA;
          this.PRESENTARA_EXAMEN_ESCUELA_PUBLICA=this.info[0].PRESENTARA_EXAMEN_ESCUELA_PUBLICA;
          this.ESCUELA_SECTOR=this.info[0].ESCUELA_SECTOR;
          this.ESCUELA_ALIANZA=this.info[0].ESCUELA_ALIANZA;
          this.ETAPA_VENTA=this.info[0].ETAPA_VENTA;
          this.CLASIFICACION_TIPIFICACION=this.info[0].CLASIFICACION_TIPIFICACION;
          this.TIPIFICACION=this.info[0].TIPIFICACION;
          this.TIPIFICACION_BI=this.info[0].TIPIFICACION_BI;
          this.CONTADOR_TOQUES=this.info[0].CONTADOR_TOQUES;
          this.OPORTUNIDAD_DUPLICADA=this.info[0].OPORTUNIDAD_DUPLICADA;
          this.FECHA_INSERCION_CRM=this.info[0].FECHA_INSERCION_CRM;
          this.FECHA_PRIMER_TOQUE=this.info[0].FECHA_PRIMER_TOQUE;
          this.FECHA_CONTACTADO=this.info[0].FECHA_CONTACTADO;
          this.FECHA_ULTIMA_CITA=this.info[0].FECHA_ULTIMA_CITA;
          this.FECHA_ULTIMA_ASISTENCIA=this.info[0].FECHA_ULTIMA_ASISTENCIA;
          this.FECHA_ULTIMA_MODIFICACION=this.info[0].FECHA_ULTIMA_MODIFICACION;
          this.NO_PERSONA=this.info[0].ID_CONTACTO;
          this.CALIDAD_REGISTRO=this.info[0].CALIDAD_REGISTRO;
          this.FECHA_INSERCION_MARCADOR=this.info[0].FECHA_INSERCION_MARCADOR;
          this.FECHA_ULTIMA_MODIFICACION_MARCADOR=this.info[0].FECHA_ULTIMA_MODIFICACION_MARCADOR;
          this.CICLO_OBTENCION=this.info[0].CICLO_OBTENCION;
          this.CICLO_ULTIMA_ASISTENCIA=this.info[0].CICLO_ULTIMA_ASISTENCIA;
          this.CICLO_ULTIMA_CITA=this.info[0].CICLO_ULTIMA_CITA;
          this.CANAL_ACTUALIZADO=this.info[0].CANAL_ACTUALIZADO;

          this.correowebinar='';
          this.telefonowebinar='';
          this.telvalidar= 0;
          this.correovalidar= 0;
          if(this.telvalidar ==1 && this.correovalidar ==1)
          {
            this.seen=false;
         
          }
          else{
            if(this.telvalidar == 0){this.seen2=true}
            else{this.seen2=false}
            if(this.correovalidar == 0){
            this.seen3=true}
            else{this.seen3=false}
          }
          //console.log(contract);
        }else{
        //let params = new URLSearchParams(location.search);
       
        
          this.reset();
          alert("Tu número de cuenta no coincide, ayúdanos a validarlo nuevamente. Si no lo recuerdas puedes consultarlo a través de nuestros medios de contacto: ");
          
        }
      })
    }
    }
    },
  enviar(){
    if(this.correovalidar == 0 ){
      console.log(this.correowebinar)
      if(this.correowebinar== ''){
        this.errors.push('Favor de modificar el campo Correo');
      }else{
        if(this.telvalidar == 0){
          if(this.telefonowebinar== ''){
          
            this.errors.push('Favor de modificar el campo Teléfono');
          }else{
            if(this.telefonowebinar.length != 10){
              this.errors.push('Favor de colocar sus 10 dígitos de numero telefónico');
            }  
            else{
              this.axio();
            }
            
          }
        }else{
          this.axio();
        }
      }
    }else{ 
      if(this.telvalidar == 0){
        if(this.telefonowebinar== ''){
          this.errors.push('Favor de modificar el campo Teléfono');
        }
        else{
           this.axio();
        }
      }else{
        this.axio();
      }
    }   
  },
  axio(){
        this.isLoading = true;
        // alert(this.correowebinar);
       const data = {
        "data":{
            NOMBRE: this.NOMBRE,
            APELLIDO_PATERNO: this.APELLIDO_PATERNO,
            APELLIDO_MATERNO: this.APELLIDO_MATERNO,
            TELEFONO_CELULAR: this.telefonowebinar,
            TELEFONO_DOMICILIO: this.TELEFONO_DOMICILIO,
            TELEFONO_TRABAJO: this.TELEFONO_TRABAJO,
            CORREO_ELECTRONICO: this.correowebinar,
            CAMPANIA_INICIAL_CARGA_BASE:this.CAMPANIA_INICIAL_CARGA_BASE,
            CAMPANIA:this.CAMPANIA,
            SUBCAMPANIA:this.SUBCAMPANIA,
            ESTADO_OPORTUNIDAD:this.ESTADO_OPORTUNIDAD,
            PRIORIDAD_MARCACION:this.PRIORIDAD_MARCACION,
            SCORE:this.info[0].SCORE,
            DESBORDE_CCUVM_CAMPUS:this.DESBORDE_CCUVM_CAMPUS,
            DESBORDE_CAMPUS_CCUVM:this.DESBORDE_CAMPUS_CCUVM,
            CICLO:this.CICLO,
            CAMPUS:this.CAMPUS,
            REGION:this.REGION,
            NIVEL:this.NIVEL,
            PROGRAMA:this.PROGRAMA,
            FUENTE_OBTENCION:this.FUENTE_OBTENCION,
            FUENTE_NEGOCIO: "WEB REGRESOS",
            TRACKING_CODE:this.TRACKING_CODE,
            TIPO_BASE:this.TIPO_BASE,
            REVALIDACION_EQUIVALENCIAS:this.REVALIDACION_EQUIVALENCIAS,
            CLAVE_ESCUELA_PROCEDENCIA:this.CLAVE_ESCUELA_PROCEDENCIA,
            NOMBRE_EMPRESA:this.NOMBRE_EMPRESA,
            PRESENTARA_EXAMEN_ESCUELA_PUBLICA:this.PRESENTARA_EXAMEN_ESCUELA_PUBLICA,
            ESCUELA_SECTOR:this.ESCUELA_SECTOR,
            ESCUELA_ALIANZA:this.ESCUELA_ALIANZA,
            ETAPA_VENTA:this.ETAPA_VENTA,
            CLASIFICACION_TIPIFICACION:this.CLASIFICACION_TIPIFICACION,
            TIPIFICACION:this.TIPIFICACION,
            TIPIFICACION_BI:this.TIPIFICACION_BI,
            CONTADOR_TOQUES:this.CONTADOR_TOQUES,
            OPORTUNIDAD_DUPLICADA:this.OPORTUNIDAD_DUPLICADA,
            FECHA_INSERCION_CRM:this.FECHA_INSERCION_CRM,
            FECHA_PRIMER_TOQUE:this.FECHA_PRIMER_TOQUE,
            FECHA_CONTACTADO:this.FECHA_CONTACTADO,
            FECHA_ULTIMA_CITA:this.FECHA_ULTIMA_CITA,
            FECHA_ULTIMA_ASISTENCIA:this.FECHA_ULTIMA_ASISTENCIA,
            FECHA_ULTIMA_MODIFICACION:this.FECHA_ULTIMA_MODIFICACION,
            NO_PERSONA:this.NO_PERSONA,
            CALIDAD_REGISTRO: "1",
            FECHA_INSERCION_MARCADOR:this.FECHA_INSERCION_MARCADOR,
            FECHA_ULTIMA_MODIFICACION_MARCADOR:this.FECHA_ULTIMA_MODIFICACION_MARCADOR,
            CICLO_OBTENCION:this.CICLO_OBTENCION,
            CICLO_ULTIMA_ASISTENCIA:this.CICLO_ULTIMA_ASISTENCIA,
            CICLO_ULTIMA_CITA:this.CICLO_ULTIMA_CITA,
            CANAL_ACTUALIZADO:this.CANAL_ACTUALIZADO
          }
          }
        this.items=data
        console.log(data)
    axios.post('https://consulta-regresos-dot-uvm-contact-center.uc.r.appspot.com/insertregresos', 
          [this.items]
           // json: true
        ).then(response2 => {
          let respuesta= response2.data;
          console.log(respuesta.errorMessage);
          if (respuesta.code=== '200'){ 
            //

            //
            this.seelayout3=false,
            this.seelayout4=true
            //this.isLoading = false
            //this.reset();
            this.errors= [];
            //this.seenbuton=false;
            
            //this.sucsess.push('Se ha confirmado tú asistencia de forma exitosa, en breve recibirás un correo con los pasos a seguir');
            //alert("Se ha confirmado tú asistencia de forma exitosa, en breve recibirás un correo con los pasos a seguir");
            //window.location = "https://unitec.mx"
          }else{
            console.log();
            alert("Ha ocurrido un error en el proceso de la solicitud, inténtelo de nuevo en un par de minutos.");
            this.isLoading = false
          }
        }).catch(e => {
          console.log(e);
        });
  },
  salir(){
    location.href="https://uvm.mx/";

  }
}
};

