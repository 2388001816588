import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import registrowebinar from '../views/popupwebinar/popup-webinar.vue'
import regresos from '../components/regresos-component.vue'
import validar from '../views/validarcuenta/validar-cuenta.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/registrowebinar',
    name: 'registrowebinar',
    component: registrowebinar,
  },
  {
    path: '/regresos',
    name: 'regresos',
    component: regresos,
  },
  {
    path: '/validar',
    name: 'validar',
    component: validar,
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
