import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import { BootstrapVue } from 'bootstrap-vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import warp from '@vue/web-component-wrapper'
//import popupwebinar from './views/popupwebinar/popup-webinar.vue';
import regresos from './components/regresos-component.vue';

Vue.component('validar-cuenta', require('./views/validarcuenta/validar-cuenta.vue').default);

Vue.use(BootstrapVue)
Vue.config.productionTip = false

const customElement = warp(Vue, regresos);
window.customElements.define('regresos-component', customElement)

new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
