
import VueLoadingButton from 'vue-loading-button'
  import axios from "axios";
  axios.defaults.headers.common = {
    'X-Requested-With': 'XMLHttpRequest',
    'X-CSRF-TOKEN': window.csrf_token
};
  export default {
      components: {
    VueLoadingButton,
  },
    /// inicializacion eventos
  mounted() {
    console.log('Component mounted.')
  },
  ///Datos vue
  data(){
    return {
      nombre:null,
      numeroalumno:null,
      telefonowebinar:null,
      correowebinar:null,
      seen: false,
      seen2: false,
      seen3: false,
      seenbuton:true,
      info:[],
      telvalidar: null,
      correovalidar: null ,
      errors: [],
      sucsess: [],
      lastname:null,
      amaterno:null,
      isLoading: false,
      isStyled: false,
      isLoading1: false,
      isStyled1: false,
    };
  },
  ///Metodos vue
  methods: {
    reset() {
      Object.assign(this.$data, this.$options.data.call(this));
    },
    consultar () {
    if (!this.numeroalumno){
                this.errors.push('El número de cuenta no debe estar vacío.');
    }else{
        if (this.numeroalumno.length <6 || this.numeroalumno.length >9) {
                    this.errors.push('La cuenta debe de contener al menos 6 dígitos y un máximo de 9.');
                }else{
         let params = new URLSearchParams(location.search);
         // let contract = params.get('prodId');
          this.isLoading1 = true;
      axios.get('https://visualdialer.uc.r.appspot.com/webinars/'+this.numeroalumno).then((result) => {
        console.log(result.data);
        this.info = result.data
        if (result.data.length>0){
          this.isLoading1 = false;
          this.seen=true
          this.nombre=this.info[0].NOMBRE;
          this.lastname=this.info[0].APELLIDO_PATERNO;
          this.amaterno=this.info[0].APELLIDO_MATERNO;
          this.correowebinar=this.info[0].EMAIL;
          this.telefonowebinar=this.info[0].CELULAR;
          this.telvalidar= this.info[0].ESTATUS_CEL;
          this.correovalidar= this.info[0].ESTATUS_EMAIL;
          if(this.telvalidar ==0 && this.correovalidar ==0)
          {
            this.seen=false;
         this.axio();
          }
          else{
            if(this.telvalidar > 0){this.seen2=true}
            else{this.seen2=false}
            if(this.correovalidar > 0){
            this.seen3=true}
            else{this.seen3=false}
          }
          //console.log(contract);
        }else{
        //let params = new URLSearchParams(location.search);
        var contract = params.get('prodId');
        axios.post('https://visualdialer.uc.r.appspot.com/errorpost/', {
          nocuenta: this.numeroalumno,
          webinarid: contract   
        }).then(response => {
          let respuesta= response.data;
          if (respuesta < 300){ 
          //
          }else{
            //
          }
        }).catch(e => {
          console.log(e);
        });
          this.reset();
          alert("Estimado exalumno/alumno tuvimos un problema para detectar tu número de cuenta; no te preocupes, ya lo estamos revisando. Puedes registrar tus datos sin seleccionar la casilla de Soy alumno/exalumno.");
          window.location.href = "https://www.unitec.mx/eventos/";
        }
      })
    }
    }
    },
  enviar(){
    if(this.correovalidar > 0 ){
      if(this.correowebinar==this.info[0].EMAIL){
        this.errors.push('Favor de modificar el campo  Correo');
      }else{
        if(this.telvalidar > 0){
          if(this.telefonowebinar==this.info[0].CELULAR){
            this.errors.push('Favor de modificar elcampo Telefono');
          }else{
            this.axio();
          }
        }else{
          this.axio();
        }
      }
    }else{ 
      if(this.telvalidar > 0){
        if(this.telefonowebinar==this.info[0].CELULAR){
          this.errors.push('Favor de modificar elcampo Telefono');
        }
        else{
           this.axio();
        }
      }else{
        this.axio();
      }
    }   
  },
  axio(){
        this.isLoading = true;
        // alert(this.correowebinar);
        let params = new URLSearchParams(location.search);
        var contract = params.get('prodId');
        axios.post('https://visualdialer.uc.r.appspot.com/webinarpost/', {
          email: this.correowebinar,
          nocuenta: this.numeroalumno,
          firstname:this.nombre,
          lastname:this.lastname,
          amaterno: this.amaterno,
          webinarid: contract 
        }).then(response => {
          let respuesta= response.data;
          if (respuesta < 300){ 
            //
            console.log(this.numeroalumno)
              axios.post('https://qa.atencionunitec.mx/api/carga-sis/', {
                no_cuenta: this.numeroalumno,
                email:this.correowebinar,
                telefono:this.telefonowebinar
              }).then(response => {
              let respuesta= response.data;
              if (respuesta < 300){ 
              console.log(respuesta);
              //window.location = "https://unitec.mx"
              }else{
              console.log(respuesta);
              }
              }).catch(e => {
              console.log(e);
              });
            //
            this.isLoading = false
            this.reset();
            this.errors= [];
            this.seenbuton=false;
            
            this.sucsess.push('Se ha confirmado tú asistencia de forma exitosa, en breve recibirás un correo con los pasos a seguir');
            //alert("Se ha confirmado tú asistencia de forma exitosa, en breve recibirás un correo con los pasos a seguir");
            //window.location = "https://unitec.mx"
          }else{
            alert("Ha ocurrido un error en el proceso de la solicitud, inténtelo de nuevo en un par de minutos.");
          }
        }).catch(e => {
          console.log(e);
        });
  }
}
};

