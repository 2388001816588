<template>
    <div>
        <br>
        <label for=""></label>
        <input type="text" class="inputs" v-model="numeroalumno1" v-on:keyup.enter="onEnter1" name="numeroalumno1" id="numeroalumno1" label="Numero de Empleado" placeholder="No. de Cuenta">
        <p v-if="errors.length">
            <b class="error">Por favor, corrija el(los) siguiente(s) error(es):</b>
            <ul>
                <li v-for="(error, index) in errors" :key="index" class="error">{{ error }}</li>
            </ul>
        </p>
        <br>
        
        <VueLoadingButton
      aria-label="Post message"
      class="button"
      @click.native="consultar1"
      :loading="isLoading"
      :styled="isStyled"
    >CONFIRMAR ASISTENCIA</VueLoadingButton>
    </div>
    
</template>

<script>
///imports
import VueLoadingButton from 'vue-loading-button'
import axios from "axios";
///headers tokens
axios.defaults.headers.common = {
    'X-Requested-With': 'XMLHttpRequest',
    'X-CSRF-TOKEN': window.csrf_token
};
export default {
     components: {
    VueLoadingButton,
  },
    /// inicializacion eventos
    mounted() {console.log('Component mounted.')},
    ///Datos vue
    data(){
        return{
            isLoading: false,
      isStyled: false,
            numeroalumno1:null,
            email:null,
            firstname:null,
            lastname:null,
            amaterno:null,
            info:[],
            errors: []
        };
    },
    ///Metodos vue
    methods: {
        reset(){
            Object.assign(this.$data, this.$options.data.call(this));
        },
        onEnter1: function(){
             if (!this.numeroalumno1){
                this.errors.push('El numero de cuenta no debe estar vacío.');
            }else{
                if (this.numeroalumno1.length <6 || this.numeroalumno1.length >9) {
                    this.errors.push('La cuenta debe de contener al menos 6 dígitos y un máximo de 9.');
                }else{
                    axios.get('https://visualdialer.uc.r.appspot.com/webinars/'+this.numeroalumno1).then((result) => {
                        this.info = result.data
                        console.log(result.data);
                        if (result.data.length>0){
                           this.firstname= this.nombre=this.info[0].NOMBRE;
                           this.lastname =this.info[0].APELLIDO_PATERNO;
                            this.amaterno=this.info[0].APELLIDO_MATERNO;
                            this.correowebinar=this.info[0].EMAIL;
                            this. enviar1();
                        }else{ 
                            this.reset();
                            alert("*Su cuenta no necesita confirmar asistencia.");
                        }
                    })
                    
                }
            }
        },
        consultar1 (){
            if (!this.numeroalumno1){
                this.errors.push('El numero de cuenta no debe estar vacío.');
            }else{
                if (this.numeroalumno1.length <6 || this.numeroalumno1.length >9) {
                    this.errors.push('La cuenta debe de contener al menos 6 dígitos y un máximo de 9.');
                }else{
                    this.isLoading = true;
                    axios.get('https://visualdialer.uc.r.appspot.com/webinars/'+this.numeroalumno1).then((result) => {
                        this.info = result.data
                        console.log(result.data);
                        if (result.data.length>0){
                           this.firstname= this.nombre=this.info[0].NOMBRE;
                           this.lastname =this.info[0].APELLIDO_PATERNO;
                            this.amaterno=this.info[0].APELLIDO_MATERNO;
                            this.correowebinar=this.info[0].EMAIL;
                            this. enviar1();
                        }else{ 
                            this.reset();
                            alert("*Su cuenta no necesita confirmar asistencia.");
                        }
                    })
                    
                }
            }
        },
        enviar1(){
            let params = new URLSearchParams(location.search);
            var contract = params.get('prodId');
            axios.post('https://visualdialer.uc.r.appspot.com/webinarpcuenta/', {
                nocuenta: this.numeroalumno1,
                firstname:this.firstname,
                lastname:this.lastname,
                amaterno: this.amaterno,
                email: this.correowebinar,
                webinarid: contract 
            }).then(response => {
                let respuesta= response.data;
                if (respuesta < 300){ 
                    this.isLoading = false
                    this.reset();
                    alert("Se ha confirmado tú asistencia de forma exitosa");
                    //window.location = "https://unitec.mx"
                }else{
                     alert("Ha ocurrido un error en el proceso de la solicitud, inténtelo de nuevo en un par de minutos.");
                }
            }).catch(e => {
                console.log(e);
            });
        },
        handleClick() {
      console.log("click"); /* eslint-disable-line */
      this.isLoading = true;
      setTimeout(() => (this.isLoading = false), 3000);
    }
    }
};
</script>

<style>
    .error{
        color:red;
    }
</style>